.Header {
    background-color: white;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-transform: uppercase;
    line-height: 1;
    font-family: Raleway, sans-serif;
    color: black;
    .SiteLogo {
        display: none;
        padding: 30px;
        margin-left: -15px;
        margin-top: -10px;
        position: fixed;
        top: 0px;
        left: 0px;
        height: 120px;
        z-index: 2000;
    }
    @keyframes spinning {
        from { transform: rotate(0deg) }
        to { transform: rotate(360deg) }
    }
    .SpinningLogo {
        animation-name: spinning;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        display: none;
        padding: 27px;
        margin-left: -15px;
        margin-top: -8px;
        position: fixed;
        top: 0px;
        left: 10px;
        height: 100px;
        z-index: 2001;
    }
    
    .SiteLogoRight {
        display: none;
        position: fixed;
        top: 30px;
        right: 20px;
        height: 40px;
        z-index: 2000;
    }
    .ShoppingCartContainer {
        position: fixed;
        top: 44px;
        right: 110px;
        z-index: 2000;
        width: 32px;
        cursor: pointer;
    }

    .ShoppingCart {
        fill-opacity: 1;
        fill: rgba(0,0,0,.5);
        stroke: none;
        // height: 100%;
    }

    .ShoppingCartContainer:hover .ShoppingCart {
        fill:rgba(0,0,0,1);
    }

    .SearchGlyphContainer {
        position: fixed;
        top: 44px;
        right: 150px;
        z-index: 2000;
        width: 32px;
        cursor: pointer;
    }

    .SearchGlyph {
        fill-opacity: 1;
        fill: rgba(0,0,0,.5);
        stroke: none;
        // height: 100%;
    }

    .SearchGlyphContainer:hover .SearchGlyph {
        fill:rgba(0,0,0,1);
    }


    .SearchBox {
        position: fixed;
        top: 44px;
        right: 190px;
        z-index: 2000;
    }
    @media (min-width: 992px) {
        .SiteLogo {
            display: block;
        }
        .SpinningLogo{
            display: block;
        }
        .SiteLogoRight {
            display: block;            
        }
        .ShoppingCartContainer {
            top: 34px;
            right: 140px;
        }
        .SearchGlyphContainer {
            top: 34px;
            right: 180px;
        }
        .SearchBox {
            right: 220px;
            top: 40px;
        }
    }
}

.Footer {
    z-index: 1000;
    font-size: .9em;
    position: fixed;
    // margin: 10px;
    padding: 15px 10px 5px 10px;
    min-height: 55px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    vertical-align: bottom;

    .LeftContent {
        color: red;
        float: left;
        .Image {
            height: 24px;
            padding-left: 10px;
        }
        .ImageLarge{
            height: 120px;
            padding-left: 10px;
            position: fixed;
            bottom: 5px;
        }
    }
    .RightContent {
        position: absolute;
        right: 50px;
        padding-right: 15px;
    }
    .CopyrightLine {
        color: black;
    }

    .ChatBubble{

        .Small {
            position: fixed;
            height: 32px;
            bottom: 15px;
            right: 15px;
        }
        .Large {
            position: fixed;
            height: 64px;
            bottom: 45px;
            right: 25px;
        }
    }
    .ChatMessage {
        position: fixed;
        bottom: 140px;
        right: 20px;
        border-radius: 5px;
        background: white;
        height: 300px;
        width: 260px;
        padding: 50px 15px 0 15px;
        box-shadow: 2px 2px 15px;
        .ChatTitle {
            font-size: 1.2em;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background-color: cyan;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 35px;
            padding: 5px;
            font-weight: 600;
        }
        .ChatBody {
            background-color: white;
            height: 239px;
            width: 100%;
            position: absolute;
            top: 35px;
            left: 0;
            padding: 10px 5px 0 5px;
        }
        .ChatInput {
            position: absolute;
            bottom: 0;
            left: -1px;
            width: 100%;
            line-height: 30px;
        }
        .ChatSend {
            height: 36px;
            position: absolute;
            bottom: 0;
            right: -1px;
            width: 84px;
            
        }
    }

}
