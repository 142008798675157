.App {
  .AppLogo {
    height: 40vmin;
    pointer-events: none;
  }
  @media (prefers-reduced-motion: no-preference) {
    .AppLogo {
      animation: AppLogoSpin infinite 20s linear;
    }
  }
  .AppHeader {
    background-color: #282c34;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
  }
  .AppLink {
    color: #61dafb;
  }
  @keyframes AppLogoSpin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}





