.Content {
    width: 100%;
    position: relative;

    .Container {
        // background-color: white;
        margin: 30px;
        padding: 10px;
        min-height: 400px;
        height: 100%;

    }
    .ContactUs {
        background-color: white;
        min-height: 500px;
        min-width: 740px;
        margin: 50px;
        border-radius: 4px;
        .EmailIcon {
            width: 40px;
            margin-right: 10px;
        }
        .Title {
            font-size: 2.5em;
            margin: 15px;
            text-align: center;
        }
        .ContactUsInfo {
            font-style: italic;
        }
        .Form {
            
            .SubmitButtonContainer {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .InputControl {
               margin: 5px;

            }
            .IsInvalid {
                background-color: rgba(255,0,0,.1);
            }

            .InputCheckbox {
                padding: 5px;
            }
        }
    }
}    
.IntroImg {
    background-color: white;
    
}

.PersonaCard {
    margin-bottom: 3rem; 
    .PersonaTitle {
        position: relative;
        z-index: 1;
        margin-bottom: 1px;
        // padding-bottom: 20px;
        .HeadingContainer {
            width: 100%;
        }
    } 
    .ImageContainer {
        position: relative;
        height: 301px;
    }
    .PersonaImg {
        position: absolute;
        // z-index: 0;
        top: 0;
        height: 300px;
        z-index: 100;
    }
    .MaskImg {
        position: absolute;
        // z-index: 0;
        top: 0;
        height: 300px;
        z-index: 101;
    }
    .PersonaDescription {
        position: relative;
        z-index: 1;
    }

    .PersonaHeading {
        position: relative;
        text-transform: uppercase;
        .Title {
            display: block;
            font-size: 1rem;
            font-weight: 800;
        }
        .DisplayName {
            display: block;
            font-size: 3rem;
            font-weight: 100;
            text-transform: none;
        }
    }
      
    @media (min-width: 992px) {
        .Persona {
            .PersonaTitle {
                position: relative;
                z-index: 1;
                margin-bottom: -1rem;
                // padding-bottom: 30px;
                .HeadingContainer {
                    width: auto;
                }
        
            } 
            .ImageContainer {
                position: relative;
                max-width: 40vw;
                height: 200px;
                left: 300px;
            }
            .PersonaImg {
                max-width: 40vw;
                height: 200px;
            }
            .MaskImg {
                max-width: 40vw;
                height: 200px;
            }
            .PersonaDescription {
                position: relative;
                z-index: 1;
                margin-top: -3rem;
                max-width: 50vw;
            }

        } 
    }
}

.PlainContent {
    background-color: white;
    padding: 48px 20px 10px 20px;
    margin: 0 auto 0px auto;
    right: auto;
    // padding-bottom: 150px;
    .EmailIcon {
        width: 40px;
        margin-right: 10px;
    }

    .Heading {
        font-size: 48px;
    }
    .InputForm {
       font-size: 1em; 
       .InputControl {
           margin-bottom: 15px;
       }
       .InputCheckbox {
           padding: 10px;
           background-color: blueviolet;
       }
    }
    .SuccessResponse {
        text-align: center;
    }
    .GoogleMaps {
        height: 300px;
    }
}  

@media (min-width: 992px) {
    .PlainContent {
        width: 75%;
    }  
}

@media (min-width: 1200px) {
    .PlainContent {
        // width: 45%;
    }
}

