/*!
 * Start Bootstrap - Business Casual v5.0.10 (https://startbootstrap.com/themes/business-casual)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-business-casual/blob/master/LICENSE)
 */

body {
  font-family: Lora, sans-serif;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Raleway, sans-serif;
}

p {
  line-height: 1.75;
}

.text-faded {
  color: rgba(255, 255, 255, 0.3);
}

.site-heading {
  /* margin-top: 2rem;
  margin-bottom: 2rem; */
  text-transform: uppercase;
  line-height: 1;
  font-family: Raleway, sans-serif;
}

.site-heading .site-heading-upper {
  display: block;
  font-size: 2rem;
  font-weight: 800;
}

.site-heading .site-heading-lower {
  font-size: 5rem;
  font-weight: 100;
  line-height: 4rem;
}

.page-section {
  /* margin-top: 5rem;*/
  margin-bottom: 5rem; 
}

.section-heading {
  text-transform: uppercase;
}

.section-heading .section-heading-upper {
  display: block;
  font-size: 1rem;
  font-weight: 800;
}

.section-heading .section-heading-lower {
  display: block;
  font-size: 3rem;
  font-weight: 100;
}

.title-image-right {
  height: 48px;
  position: relative;
  left: -20px;
  top: -30px;
}

@media (min-width: 992px) {
  .title-image-right {
    left: -20px;
  }
  
}


.bg-faded {
  background-color: rgba(255, 255, 255, 0.85);
}

#mainNav {
  /* background-color: rgba(205, 227, 231, 0.9); */
  font-family: Raleway, sans-serif;
}

/* #mainNav .navbar-brand {
  color:#000000;
} */

#mainNav .navbar-nav {
  text-align: right;
}

#mainNav .navbar-nav .nav-item {
  padding: 5px;
}
@media (min-width: 992px) {
  #mainNav .navbar-nav .nav-item {
    padding: 0px;
  }
}

#mainNav .navbar-nav .nav-item .nav-link {
  color: rgba(0, 0, 0, .5);
  font-weight: 700;
  font-size: 1.2em;
}

#mainNav .navbar-toggler {
  color: #000000;
  font-weight: 800;
}
#mainNav .navbar-nav .nav-item .nav-link:hover {
  color: rgba(0, 0, 0, 0.9);
  /* background-color: cornflowerblue; */
}
#mainNav .navbar-nav .nav-item .active{
  color: rgba(0, 0, 0, 0.9);
}

@media (max-width: 991px) {
  #mainNav .navbar-collapse {
    width: 300px;
    position: absolute;
    top: 57px;
    right: 0;
    padding-right: 40px;
    padding-left: 40px;
    background-color: white;
    z-index: 1000;
  }
}
@media (min-width: 992px) {
  #mainNav .navbar-nav .nav-item .nav-link {
    font-size:1.2em;
  }

  /* #mainNav .navbar-nav .nav-item .nav-link:hover {
    color: rgba(0, 0, 0, 0.9);
  } */

}
/* nav.hidden  {
  height: 60px;
}

nav.hidden > nav.navbar {
  height: 60px;
} */

/* nav.hidden .d-lg-none {
  display: block !important;
} */

.btn-xl {
  font-weight: 700;
  font-size: 0.8rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.intro {
  position: relative;
}

@media (min-width: 992px) {
  .intro .intro-img {
    background-color: rgba(255,255,255,1);
    width: 75%;
    float: right;
  }
  .intro .intro-text {
    left: 0;
    width: 60%;
    margin-top: 3rem;
    position: absolute;
  }
  .intro .intro-text .intro-button {
    width: 100%;
    left: 0;
    position: absolute;
    bottom: -2rem;
  }
}

@media (min-width: 1200px) {
  .intro .intro-text {
    width: 45%;
  }
}

.cta {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: rgba(230, 167, 86, 0.9);
}

.cta .cta-inner {
  position: relative;
  padding: 3rem;
  margin: 0.5rem;
  background-color: rgba(255, 255, 255, 0.85);
}

.cta .cta-inner:before {
  border-radius: 0.5rem;
  content: '';
  position: absolute;
  top: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem;
  right: -0.5rem;
  border: 0.25rem solid rgba(255, 255, 255, 0.85);
  z-index: -1;
}

@media (min-width: 992px) {
  .about-heading .about-heading-img {
    position: relative;
    z-index: 0;
  }
  .about-heading .about-heading-content {
    margin-top: -5rem;
    position: relative;
    z-index: 1;
  }
}

@media (min-width: 992px) {
  .product-item .product-item-title {
    position: relative;
    z-index: 1;
    margin-bottom: -3rem;
  }
  .product-item .product-item-img {
    position: relative;
    z-index: 0;
    max-width: 60vw;
  }
  .product-item .product-item-description {
    position: relative;
    z-index: 1;
    margin-top: -3rem;
    max-width: 50vw;
  }
}

.list-hours {
  font-size: 0.9rem;
}

.list-hours .list-hours-item {
  border-bottom: 1px solid rgba(230, 167, 86, 0.5);
  padding-bottom: .25rem;
  margin-bottom: 1rem;
  font-style: italic;
}

.list-hours .list-hours-item.today {
  font-weight: bold;
  color: #e6a756;
}

@media (min-width: 992px) {
  .list-hours {
    width: 50%;
    font-size: 1.1rem;
  }
}

.address strong {
  font-size: 1.2rem;
}

.footer {
  background-color: rgba(47, 23, 15, 0.9);
}

.text-primary {
  color: #e6a756 !important;
}

.bg-primary {
  background-color: #e6a756 !important;
}

.btn {
  box-shadow: 0px 3px 3px 0px rgba(33, 37, 41, 0.1);
}



.font-weight-light {
  font-weight: 100 !important;
}
