.StickyNav {
  z-index: 1000;
  background-color: white;
  height:100px;
  position: -webkit-sticky; /* This is needed for Safari support */
  z-index: 1000;
 
  .NavBrand {
    position: fixed;
    left: 50px;
    top: 30px;
  }

  .NavBarToggler {
    position: fixed;
    right: 5px;
    top: 30px;
  }

  .NavLogo {
    // display: none;
    height: 40px;
    position: fixed;
    left: 5px;
    top: 30px;
  }
  
  @media (min-width: 992px) {
    .NavLogo {
      display: none;
      height: 60px;
      position: absolute;
      // display: none;
    }

    nav.unfixed img {
      display: none;
    }
  }
}
