.SearchBoxContainer {
    background-color: white;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-transform: uppercase;
    line-height: 1;
    font-family: Raleway, sans-serif;
    color: black;

    .SearchGlyphContainer {
        position: fixed;
        top: 44px;
        right: 150px;
        z-index: 2000;
        width: 32px;
        cursor: pointer;
    }

    .SearchGlyph {
        fill-opacity: 1;
        fill: rgba(0,0,0,.5);
        stroke: none;
        // height: 100%;
    }

    .SearchGlyphContainer:hover .SearchGlyph {
        fill:rgba(0,0,0,1);
    }


    .SearchBox {
        position: fixed;
        top: 44px;
        right: 190px;
        z-index: 2000;
    }
    @media (min-width: 992px) {
        .SearchGlyphContainer {
            top: 34px;
            right: 180px;
        }
        .SearchBox {
            right: 220px;
            top: 40px;
        }
    }
}
