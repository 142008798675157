.FacebookGlyph {
    fill: #ffffff;
}    
.FacebookPrimary {
    fill: #000000;
    stroke: none;
}
.FacebookPrimary:hover {
    fill: #0f93f3;
}
.FacebookGlyph:hover .FacebookPrimary {
    fill: #0f93f3;
}
.FacebookSecondary {
    fill: #ffffff;
    stroke: none;
}

.TwitterGlyph {
    fill: #ffffff;
}
.TwitterPrimary {
    fill: #000000;
    stroke: none;
}
.TwitterPrimary:hover {
    fill: #43bffb;
}
.TwitterGlyph:hover .TwitterPrimary {
    fill: #43bffb;
}
.TwitterSecondary {
    fill: #ffffff;
    stroke: none;
}

.InstagramGlyph {
    fill: #ffffff;
}
.InstagramPrimary {
    fill: #000000;
    stroke: none;
}
.InstagramPrimary:hover {
    fill: #d6005a;
}
.InstagramGlyph:hover .InstagramPrimary {
    fill: #d6005a;
}
.InstagramSecondary {
    fill: #ffffff;
    stroke: none;
}

.LinkedInGlyph {
    fill: #ffffff;
}
.LinkedInPrimary {
    fill: #000000;
    stroke: none;
}
.LinkedInPrimary:hover {
    fill: #0a66c2;
}
.LinkedInGlyph:hover .LinkedInPrimary {
    fill: #0a66c2;
}
.LinkedInSecondary {
    fill: #ffffff;
    stroke: none;
}

.YoutubeGlyph {
    fill: #ffffff;
}
.YoutubePrimary {
    fill: #000000;
    stroke: none;
}
.YoutubePrimary:hover {
    fill: #ff0000;
}
.YoutubeGlyph:hover .YoutubePrimary {
    fill:#ff0000;
}
.YoutubeSecondary {
    fill: #ffffff;
    stroke: none;
}
